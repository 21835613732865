import "./App.css";
import { BrowserRouter , Routes, Route } from "react-router-dom";
import Layout from "./Pages/Layout";
import { lazy } from "react";
import { Suspense } from "react";
import Loading from "./Components/loading";

// Homepage
const Home = lazy(() => import('./Pages/Home'))

// Product
const Product = lazy(() => import('./Pages/Product'))

// About
const About = lazy(() => import('./Pages/About'))

// Blog
const Blog = lazy(() => import('./Pages/Blog'))
const BlogDetail = lazy(() => import('./Pages/BlogDetail'))

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/product" element={<Product />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/detail/:id" element={<BlogDetail />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter >
  );
}

export default App;
